[contenteditable]:empty:focus::before,[contenteditable]:empty::before {
     content: attr(data-placeholder);
     color:black;
     font-style: italic;
}
[contenteditable] {
    background-color:whitesmoke;
    overflow-y:auto;
    /* min-height:140px; */
    /* height: 140px; */
    padding:5px;
    flex:3;
} 
.label{
    cursor: pointer;
    text-shadow: 0px  -1px 0px rgba(0,0,0,.5);
    -webkit-box-shadow: 0px 0px 0px #2b638f, 0px 1px 10px rgba(0,0,0,.4);
-moz-box-shadow: 0px 0px 0px #2b638f, 0px 1px 10px rgba(0,0,0,.4);
box-shadow: 0px 0px 0px #2b638f, 0px 1px 10px rgba(0,0,0,.4);
}
.label2{
    cursor: pointer;
}
.label2:hover{
    border:1px solid blue;
    background-image: linear-gradient(bottom, rgb(79,142,191) 0%, rgb(102,166,214) 100%);
    background-image: -o-linear-gradient(bottom, rgb(79,142,191) 0%, rgb(102,166,214) 100%);
    background-image: -moz-linear-gradient(bottom, rgb(79,142,191) 0%, rgb(102,166,214) 100%);
    background-image: -webkit-linear-gradient(bottom, rgb(79,142,191) 0%, rgb(102,166,214) 100%);
    background-image: -ms-linear-gradient(bottom, rgb(79,142,191) 0%, rgb(102,166,214) 100%);
 
    background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(0, rgb(79,142,191)),
        color-stop(1, rgb(102,166,214))
    );
}
.label:hover{
    border:1px solid blue;
    background-image: linear-gradient(bottom, rgb(79,142,191) 0%, rgb(102,166,214) 100%);
    background-image: -o-linear-gradient(bottom, rgb(79,142,191) 0%, rgb(102,166,214) 100%);
    background-image: -moz-linear-gradient(bottom, rgb(79,142,191) 0%, rgb(102,166,214) 100%);
    background-image: -webkit-linear-gradient(bottom, rgb(79,142,191) 0%, rgb(102,166,214) 100%);
    background-image: -ms-linear-gradient(bottom, rgb(79,142,191) 0%, rgb(102,166,214) 100%);
 
    background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(0, rgb(79,142,191)),
        color-stop(1, rgb(102,166,214))
    );
}
#text-input-container{
    padding:6px;
    background:whitesmoke;
    flex:0.5;
}