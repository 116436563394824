.adminSettings
{
    width: 100%;
    height: 100%;
}

.adminSettings_modal .modal-content
{
    width: 400px;
    height: 500px;
    border-radius: 20px !important;
    margin: auto;
}

.add_user_modal
{
    width: 400px ;
    height: 600px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.edit_user_modal
{
    width: 400px ;
    height: 600px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.input_style_admin
{
    display: table;
    margin: 0 auto;
    width: 240px;
    height: 40px;
    border-radius: 20px;    
}

.add_user_toggle
{
    display: flex;
    width: 150px;
    flex-direction: row;
    justify-content: space-between;
}

.add_user_modal_form
{
    margin-top: 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-around;
    align-items: center;
}

.user_info_all_admin
{
    margin-top: 10%;
    width: 80%;
    height: 60%;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
    padding: 10px;
}

.user_info_row
{
    width: 100%;
    padding: 15px;
    background-color: #E7E7E7;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.user_info_email
{
    font-size: 19px;
    font-weight: 400;
}

.user_info_uid
{
    font-weight: 400;
    color: #909090;
}

.user_info_status
{
    font-size: 14px;
    font-weight: 500;
}

.user_info_other_details
{
    width: 30%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.user_info_edit_options
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.admin_sett_editLogo
{
    cursor: pointer;
    padding: 10px;
}

.admin_sett_trashLogo
{
    cursor: pointer;
    padding: 10px;
}

.user_info_all_admin::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

.user_info_all_admin::-webkit-scrollbar-track {
    border-radius: 4px;
    margin: 15px 0;
    padding: 15px 0;
}
   
.user_info_all_admin::-webkit-scrollbar-thumb {
background: #e7901f; 
border-radius: 10px;
}

/* Handle on hover */
.user_info_all_admin::-webkit-scrollbar-thumb:hover {
background: #ff7628; 
}