.sideNavBar_buttons
{
    background-color: #EFEFEF;
    cursor: pointer;
    margin: auto;
    width: 200px;
    height: 60px;
    margin-bottom: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sideNavBar_buttons:hover
{
    background-color: white;
}