@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');

.loginPage
{
    width:100vw;
    height: 100vh;
    overflow: hidden !important;
    background-repeat: no-repeat !important;
    background-position-x: right !important;
    background-position-y:center !important;
    /* background: url("https://studio.theare.ai/app/static/media/image.4acb24b7.png"); */
    background-color: #ffff;
    font-family: 'Rubik', sans-serif !important;
}

@media only screen and (max-width:600px) {
    .loginPage{
       background-size: auto 50%;
       background-position-y:-5px !important;
    }
}

.login_title
{
    color: #000000;
    position: absolute;
    left: 9%;
    top: 5%;
}

.login_title .title_ai
{
    color: #E58200;
}


.login_submit
{
    background: #000000;
    color: white;
    border-radius: 6px;    
    padding: 10px;
    text-align: center;
    cursor: pointer;
    float: left;
    width: 250px;
    transition: width 0.2s;
}

.register_enabled .login_submit{
    width: 100px;
}

.register_text{
    padding-top: 10px;
    padding-bottom: 10px;
    transition: ease 0.5s;
    right: 20px;
    position: absolute;
}

.register_toggle{
    margin-top: 10px;
    margin-bottom: 10px;
    background: #B97113;
    color: white;
    border-radius: 21px;
    width: 250px;
    text-align: center;
    cursor: pointer;
}

.login_buttons
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10%;
}