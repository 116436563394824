.api_key_button
{ 
    margin: 0 auto;
    cursor: pointer;
    border:1px solid #FF7628 !important;
    color: #FFFFFF !important;
    background:#E7901F !important;
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
    vertical-align:middle;
    height: 40px;
    border-radius: 20px;
    text-align: center;   
    max-width: 120px;
    padding: 20px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.customScrollbar::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

.customScrollbar::-webkit-scrollbar-track {
    border-radius: 4px;
    margin: 15px 0;
    padding: 15px 0;
}
   
.customScrollbar::-webkit-scrollbar-thumb {
background: #e7901f; 
border-radius: 10px;
}

/* Handle on hover */
.customScrollbar::-webkit-scrollbar-thumb:hover {
background: #ff7628; 
}