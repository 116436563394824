.billingInfo
{
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.billing_info_cards
{    
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 30px;
}
.billing_info_cards_block
{
    width: 350px;
    height: 275px;
    background-color: #F9F9F9;
    ;
    margin: 0 15px;
    border-radius: 15px;
}
.billing_info_not_Admin_card
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.credits_Available
{
    text-align: center;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bill_info
{
    padding: 20px;
    font-weight: 500;
}

.bill_info_row
{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.billing_info_card_arrows
{
    display: inline-flex;
    flex-direction: column;
    cursor: pointer;
}

.billing_info_card_number
{
    width: 61px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.order_info_table
{
    background-color: #F9F9F9;
    width: 90%;
    margin: 15px auto;
    border-radius: 20px;
    padding: 10px 10px;
    
    height: 40%;
    overflow-y: auto;
}
.order_info_column
{
    width: 20%;
    margin: 10px 10px;
}
.order_info_table_header
{
    font-weight :700;
    font-Size   :14px;
    display     :flex;
    align-items :center;
    justify-content: center;
}
.order_info_table_body
{
    font-weight :400;
}
.order_info_table_row
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.order_info_table::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}
  
.order_info_table::-webkit-scrollbar-track {
    border-radius: 4px;
    margin: 15px 0;
    padding: 15px 0;
}
.order_info_table::-webkit-scrollbar-thumb {
background: #e7901f; 
border-radius: 10px;
}

/* Handle on hover */
.order_info_table::-webkit-scrollbar-thumb:hover {
background: #ff7628; 
}

@media(max-height: 718px){
    .order_info_table{
        height: 30% !important;
    }
}