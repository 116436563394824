a, div, p, span, h1, h2, h3, h4, h5, h6 {
    font-family: Rubik !important;
}

.panel-entity-container {
    overflow-y: auto;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}

::-webkit-scrollbar {
    /* WebKit */
    width: 6px;
    height: 0px;
}

::-webkit-scrollbar-track{
    background: #f1f1f1;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb{
    background: #444;
    border-radius: 3px;
}