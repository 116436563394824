@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

.changePassword
{
    width: 100%;
    height: 100%;
    align-items: center;
}

.changePassword_form
{
    margin-top: 10%;
    height: 100%;
}

.login_title_profile
{
    position: relative;
    top: 15px;
    margin-right: 25px;
}

.input_style_profile
{
    display: table;
    margin: 0 auto;
    width: 400px;
    height: 50px;
    border-radius: 25px;
}


.input_style_profile::placeholder
{
    font-family: Roboto;
    font-weight: 500;
    padding-left: 10px;
    color: #747272;
    /* color: black; */
}


.change_button
{ 
    margin: 0 auto;
    cursor: pointer;
    border:1px solid #FF7628;
    color: #FFFFFF;
    background:#E7901F;
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
    vertical-align:middle;
    height: 40px;
    border-radius: 20px;
    text-align: center;   
    max-width: 120px;
    padding: 20px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}