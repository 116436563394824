.dropzone-container {
  background-color: red;
  background-color: white;
  border: 2px dashed rgb(7, 7, 7);
  border-radius: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
}

.upload-icon {
  opacity: 0.3;
  height: 3em;
  width: 3em;
}

.file-input {
  display: none;
}